import React, { useContext } from 'react'
import { Link } from 'gatsby'
import { SmallerContainer, ThemeContext } from 'components/common'
import { MagicalButton, Wrapper, Flex } from './styles'
// import Resume from "./ElomTsiagbey_Resume.pdf"

export const Intro = () => {
  const { theme } = useContext(ThemeContext)
  return (
    <Wrapper theme={theme} as={SmallerContainer}>
      <h1>Hello, I'm Elom</h1>
      <p>Full-Stack Developer. Entrepreneur. Curious Mind. </p>
      <Flex>
        {/* <MagicalButton href={Resume} rel="noopener noreferrer" target="_blank">
            View resume
         </MagicalButton> */}
        <MagicalButton as={Link} to="/contact">
          Get In Touch
        </MagicalButton>
      </Flex>
    </Wrapper>
  )
}
