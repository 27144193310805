import React, { useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Container, ThemeContext } from 'components/common'
import Skill from './Skill'
import { Wrapper, Title, Grid } from './styles'

export const Skills = () => {
  const { theme } = useContext(ThemeContext)
  const {
    skills: { edges },
  } = useStaticQuery(graphql`
    query {
      skills: allSkillsYaml {
        edges {
          node {
            id
            title
            icon
            description
          }
        }
      }
    }
  `)
  return (
    <Wrapper theme={theme}>
      <Container>
        <Title>Skills & Interests</Title>
        <Grid>
          {edges.map(({ node }) => (
            <Skill key={node.id} {...node} />
          ))}
        </Grid>
      </Container>
    </Wrapper>
  )
}
