import React from 'react'
import { Wrapper, Grow, Title, Desc } from './styles'

export default ({ icon, title, description }) => (
	<Wrapper>
		<Grow src={icon} alt={title} />
		<Title>{title}</Title>
		<Desc>{description}</Desc>
	</Wrapper>
)
